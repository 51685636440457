// custom mixins
@mixin centered-block($width, $marginTop) {
  position: relative;
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: $width;
}

@mixin centered-positioned() {
  transition: all ease-out 300ms;
  transform: translate(-50%, -50%);

  position: absolute;
  top: 50%;
  left: 50%;

}