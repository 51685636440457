
@import "./../variables";

.bets-closed-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9001;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: $containersBg;
  opacity: 0.8;

  text-align: center;

  &.hideLoader {
    display: none;
  }

  &.transparentAbsolutePreLoader {
    position: absolute;
    opacity: 0.5;
    z-index: 9000;
  }

  &.transparentBackground {
    background: transparent;
  }

  &.show-in-parent {
    position: absolute;
    width: 100%;

    h1 {
      font-size: 2vh;
    }
    .countdown-label {
      font-size: 10vh;
    }
  }

  h1 {
    display: inline-block;
    margin-top: -10%;

    font-size: 3.75rem;
    text-transform: capitalize;
  }

  .countdown-label {
    font-size: 25vh;
    text-align: center;
    color: #f9fdfd;
    text-shadow: 0 7px #14333e;
  }
}