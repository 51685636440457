.fl-cntr {
  display: -ms-flexbox;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.fl-cntr-col {
  display: -ms-flexbox;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  flex-flow: column nowrap;
}

.flic,
.flir {
  flex: 1 1;
}

.flic {
  display: -ms-flexbox;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  flex-flow: column nowrap;
}

.flir {
  display: -ms-flexbox;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  flex-flow: row nowrap;
}

.fli-g-0,
.fli-s-0,
.fli-0,
.fli-g-1,
.fli-s-1,
.fli-1,
.fli-g-2,
.fli-s-2,
.fli-2,
.fli-g-3,
.fli-s-3,
.fli-3,
.fli-g-4,
.fli-s-4,
.fli-4,
.fli-g-5,
.fli-s-5,
.fli-5,
.fli-g-6,
.fli-s-6,
.fli-6,
.fli-g-7,
.fli-s-7,
.fli-7,
.fli-g-8,
.fli-s-8,
.fli-8,
.fli-g-9,
.fli-s-9,
.fli-9,
.fli-g-10,
.fli-s-10,
.fli-10,
.fli-g-11,
.fli-s-11,
.fli-11,
.fli-g-12,
.fli-s-12,
.fli-12,
.fli-g-13,
.fli-s-13,
.fli-13,
.fli-g-14,
.fli-s-14,
.fli-14,
.fli-g-15,
.fli-s-15,
.fli-15,
.fli-g-16,
.fli-s-16,
.fli-16,
.fli-g-17,
.fli-s-17,
.fli-17,
.fli-g-18,
.fli-s-18,
.fli-18,
.fli-g-19,
.fli-s-19,
.fli-19,
.fli-g-20,
.fli-s-20,
.fli-20 {
  min-height: 0;
  min-width: 0;
}

.fli-auto {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.fli-g-0,
.fli-0 {
  flex-grow: 0;
}

.fli-s-0,
.fli-0 {
  flex-shrink: 0;
}

.fli-0 {
  flex-basis: 0%;
}

.fli-0-5 {
  flex-basis: 0.5%;
}

.fli-g-1,
.fli-1 {
  flex-grow: 1;
}

.fli-s-1,
.fli-1 {
  flex-shrink: 1;
}

.fli-1 {
  flex-basis: 1%;
}

.fli-g-2,
.fli-2 {
  flex-grow: 2;
}

.fli-s-2,
.fli-2 {
  flex-shrink: 2;
}

.fli-2 {
  flex-basis: 2%;
}

.fli-g-3,
.fli-3 {
  flex-grow: 3;
}

.fli-s-3,
.fli-3 {
  flex-shrink: 3;
}

.fli-3 {
  flex-basis: 3%;
}

.fli-g-4,
.fli-4 {
  flex-grow: 4;
}

.fli-s-4,
.fli-4 {
  flex-shrink: 4;
}

.fli-4 {
  flex-basis: 4%;
}

.fli-g-5,
.fli-5 {
  flex-grow: 5;
}

.fli-s-5,
.fli-5 {
  flex-shrink: 5;
}

.fli-5 {
  flex-basis: 5%;
}

.fli-g-6,
.fli-6 {
  flex-grow: 6;
}

.fli-s-6,
.fli-6 {
  flex-shrink: 6;
}

.fli-6 {
  flex-basis: 6%;
}

.fli-g-7,
.fli-7 {
  flex-grow: 7;
}

.fli-s-7,
.fli-7 {
  flex-shrink: 7;
}

.fli-7 {
  flex-basis: 7%;
}

.fli-g-8,
.fli-8 {
  flex-grow: 8;
}

.fli-s-8,
.fli-8 {
  flex-shrink: 8;
}

.fli-8 {
  flex-basis: 8%;
}

.fli-g-9,
.fli-9 {
  flex-grow: 9;
}

.fli-s-9,
.fli-9 {
  flex-shrink: 9;
}

.fli-9 {
  flex-basis: 9%;
}

.fli-g-10,
.fli-10 {
  flex-grow: 10;
}

.fli-s-10,
.fli-10 {
  flex-shrink: 10;
}

.fli-10 {
  flex-basis: 10%;
}

.fli-g-11,
.fli-11 {
  flex-grow: 11;
}

.fli-s-11,
.fli-11 {
  flex-shrink: 11;
}

.fli-11 {
  flex-basis: 11%;
}

.fli-g-12,
.fli-12 {
  flex-grow: 12;
}

.fli-s-12,
.fli-12 {
  flex-shrink: 12;
}

.fli-12 {
  flex-basis: 12%;
}

.fli-g-13,
.fli-13 {
  flex-grow: 13;
}

.fli-s-13,
.fli-13 {
  flex-shrink: 13;
}

.fli-13 {
  flex-basis: 13%;
}

.fli-g-14,
.fli-14 {
  flex-grow: 14;
}

.fli-s-14,
.fli-14 {
  flex-shrink: 14;
}

.fli-14 {
  flex-basis: 14%;
}

.fli-g-15,
.fli-15 {
  flex-grow: 15;
}

.fli-s-15,
.fli-15 {
  flex-shrink: 15;
}

.fli-15 {
  flex-basis: 15%;
}

.fli-g-16,
.fli-16 {
  flex-grow: 16;
}

.fli-s-16,
.fli-16 {
  flex-shrink: 16;
}

.fli-16 {
  flex-basis: 16%;
}

.fli-g-17,
.fli-17 {
  flex-grow: 17;
}

.fli-s-17,
.fli-17 {
  flex-shrink: 17;
}

.fli-17 {
  flex-basis: 17%;
}

.fli-g-18,
.fli-18 {
  flex-grow: 18;
}

.fli-s-18,
.fli-18 {
  flex-shrink: 18;
}

.fli-18 {
  flex-basis: 18%;
}

.fli-g-19,
.fli-19 {
  flex-grow: 19;
}

.fli-s-19,
.fli-19 {
  flex-shrink: 19;
}

.fli-19 {
  flex-basis: 19%;
}

.fli-g-20,
.fli-20 {
  flex-grow: 20;
}

.fli-s-20,
.fli-20 {
  flex-shrink: 20;
}

.fli-20 {
  flex-basis: 20%;
}

$columns: 21;

@mixin make-flex-row() {
  display: flex;
  flex-wrap: wrap;
}
@mixin make-column($columnIndex, $columnWidth) {
  position: relative;
  flex: 0 0 $columnWidth;
  max-width: $columnWidth;
  width: $columnWidth;
}

// set flex row
.flex-row {
  @include make-flex-row();
}
// generate flex columns
@for $i from 0 through ($columns) {
  $columnWidth: 100% / $columns * $i;
  .flex-col-#{$i} {
    @include make-column($i, $columnWidth);
  }
}
.flex-column {
  flex-direction: column;
}

@media screen and (max-width: 1024px) {
  .flex-row {
    flex-direction: column;
    [class*="flex-col-"] {
      //flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
  }

  .fl-cntr-left-col {
    position: fixed;
    height: 100%;
    width: 320px;
    left: -320px;
  }

  //.fl-cntr-right-col {
  //  position: fixed;
  //  height: 100%;
  //  width: 320px;
  //  right: -320px;
  //}

  .fl-cntr-col {
    max-width: 100%;
    width: 100%;
  }
}
