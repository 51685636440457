@import "./../variables";

.withdraw-history-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  //z-index: 10000;
  background-color: $containersBg;

  &.preloader-layer {
    z-index: $preloaderLayer;
  }
}


.withdraw-aside {
  transition: all ease 300ms;

  position: fixed;
  z-index: 1;
  height: 100%;
  width: 30.6%;
  right: -100%;

  &.show-aside {
    transition: all ease 300ms;

    position: absolute;
    right: 0;
    z-index: $asideBlockLayer;

  }
}

@media screen and (max-width: $maxMobileScreenWidth) {

  .top-title {
    margin: 0.625rem 0 0.625rem;
  }

  .withdraw-aside {
    width: 100%;
    //z-index: 10000;

    &.preloader-layer {
      z-index: $preloaderLayer;
    }

    .tabs .tabs-content .basket-tab-content {
      padding: 0 0.5rem 0.5rem;
    }
  }
}
