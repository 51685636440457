@import "./../variables";

.accordion {
  transition: 260ms cubic-bezier(0.4, 0, 0.2, 1);

  button.btn {
    opacity: 1;
  }

  &:hover button {
    background-color: #00314e;
  }

  &:hover button.accordion-toggle-success {
    background-color: #216156;
  }

  &.accordion-is-open button.accordion-toggle-success {
    background-color: #216156;
  }

  &.accordion-is-open button {
    background-color: #00314e;
  }
}

.accordion-toggle {
  cursor: pointer;
  transition: min-height 260ms cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  margin: 0;
  padding: 0.562rem 1.25rem;
  min-height: 3.75rem;
  position: relative;
  width: 100%;
  text-align: left;
  background: #002840;
  font-family: inherit;
  outline: none;

  &-alarm {
    background-image: url("./../../assets/images/video/live-icon.png"),
      linear-gradient(
        180deg,
        rgba(209, 54, 61, 1) 0%,
        rgba(209, 54, 61, 1) 45%,
        rgba(201, 24, 27, 1) 50%,
        rgba(201, 24, 27, 1) 100%
      );
    background-size: 7rem, auto;
    background-position: 98% 45%, 0 0;
    background-repeat: no-repeat, no-repeat;
  }
  &-disable {
    background: $betsClosedBg;
    .accordion-svg {
      opacity: 0;
    }
  }
  &-delay {
    background: $raceFinishedBg;
  }
  &-success {
    background: $winningTicket;
  }

  &:focus:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -0.125rem;
    right: -0.125rem;
    border: 0.187rem solid #68b2e3;
    z-index: 1;
  }
  &:focus:not(.focus-visible):after {
    display: none;
  }
}

.filler {
  background: #68b2e3;
  height: 100%;
  opacity: 0.3;
  position: absolute;
  left: 0;
  border-radius: inherit;
  z-index: 1;
  transition: height 0.2s ease-in;
}

.accordion-svg {
  color: #ffffff;
  margin-right: 0.937rem;
  width: 1.25rem;
  height: 1.25rem;
  transition: transform 260ms cubic-bezier(0, 1, 0, 1);
  transform: rotate(0);
}

.accordion-is-closed + .accordion-is-open {
  margin-top: 0.625rem;
}
.accordion + .accordion {
  .accordion-divider {
    display: block;
  }
}
.accordion-is-open + .accordion-is-closed {
  .accordion-divider {
    display: none;
  }
}

.collapse-css-transition {
  /* Accordion library */
  transition: height 260ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &.success {
    background-color: #0c463c;
  }
}

@media screen and (max-width: 359px) {
  .accordion-toggle {
    padding: 15px;
    font-size: 12px;
  }
}
