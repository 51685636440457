// betslip component of the basket container
@import "./StakeOperation";
@import "./ConfirmPlaceBets";

.stake-amount-input.danger {
  border: 1px $dangerColor solid!important;
  box-shadow: inset 0px 0px 2px $dangerColor;
}

.event-bets-block {
  & + .event-bets-block {
    margin-top: 1.875rem;
  }

  &-header {
    position: relative;

    padding: 0 1.875rem 0 0;
    margin-bottom: 0.625rem;

    .close-button-alt {
      position: absolute;
      top: 5px;
      right: 15px;
    }
    .top-title {
      margin: 0.625rem 0 0.25rem;
    }
  }
  &-main {
    margin: 0 0;
  }


  // list of bets in betslip
  &-list {
    &-item {
      position: relative;

      padding: 0.875rem 2.25rem 0.875rem 1.25rem;


      background-color: $tableBgLight;

      color: $black;
      font-size: 1.125rem;
      line-height: 1.5rem;

      & + li {
        margin-top: 0.875rem;
      }

      .close-button {
        position: absolute;
        top: 40%;
        right: 19px;
      }

      .bet-name {
        margin: 0;
        font-weight: 300;
      }

      .win,
      .odds {
        font-weight: 600;
      }
      .bet-status {
        display: inline-block;
        height: 100%;
        width: 45%;


      }
      .bet-stake {
        position: relative;

        display: inline-block;
        height: 100%;
        padding-left: 0.625rem;
        width: 45%;

        &::after {
          content: '';

          position: absolute;
          top: 0.25rem;
          left: -0.625rem;

          display: block;
          width: 0px;
          height: 90%;

          border-left: 1px #9ca4a9 dashed;

        }

        label {
          margin-bottom: 1px;
          display: inline-block;

          font-size: 1rem;
          font-weight: 300;
        }

        input {
          transition: all ease 300ms;

          display: block;
          height: 1.25rem;
          width: 40%;

          padding: 0 0.375rem;

          border-radius: 0;
          border: 1px $bgContainerBgLighter solid;

          font-weight: 600;

          &.readonly {
            transition: all ease 300ms;

            background: transparent;
            border: none;

            font-weight: 600;
          }
        }

        &.readonly-input {
          input.readonly {
            padding: 0;

            font-size: 1.125rem;
          }
        }
      }
    }
  }
}
