@import "./colors";

$bodyBg: $nevada;
$containersBg: $shark;
$bgContainerBgLighter: $capeCod;
$bgContainerBgVeryLight: $nevada;

$itemBg: $woodsmoke;

$activeStateColor: $deepCerulean;
$dangerColor: $red;
$warningTextColor: $lightningYellow;

$videoHeaderColor: $lochmara;
$headingColor: $alabaster;

$tableBgLight: $alabaster2;
$tableLightTextColor: $black;
$tableCellActive: $deepCerulean;

$modalBackdropBg: $iron;
$modalBackdropBgDark: $woodsmoke;
$inputBorder: $shark;

$jackpotColor: $lightningYellow;

$textColor: $alabaster;
$disableStateBg: $trout;

$inactiveBgColor: $rollingStone;

$tabTitles: $lochmara;
$tabTitlesBorder: $lightningYellow;
$tabTitleText: $iron;

$placeBetBtnBg: $lightningYellow;
$actionBtnBg: $lightningYellow;

$liveColorBg: $crimson;
$betsClosedBg: $mediumTurquoise;
$raceFinishedBg: $cerulean;

$maxMobileScreenWidth: 1024px;
$minDesktopScreenWidth: $maxMobileScreenWidth + 1;

$infoPopupBg: $caribbeanGreen;
$warningPopupBg: $jasper;
$helpPopupBg: $brass;

$asideBlockLayer: 9999;
$preloaderLayer: 10000;
$preloaderExtraLayer: $preloaderLayer + 1;
$gameSelectLayer: $preloaderExtraLayer + 1;

$errorTooltipBg: $contessa;
$winningTicket: $greenPea;
