@import "./mixins";

.centered-block {
  @include centered-block(90%, 0);
}

#LOG {
  position: fixed;
  z-index: 999999;
  background-color: #0accab;
  color: white;
  width: 100%;
  border: red 1px solid;
  //height: 10px;
  top: 0;
  left: 0;
  font-size: 10px;
  font-weight: 600;
  p {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

.no-bg {
  background-color: transparent;
}
// margin/padding utility
.mb-5 {
  margin-bottom: 5px;
}

// text utility
.underline {
  text-decoration: underline;
}
.hover {
  &:hover {
    opacity: 0.85;
  }
}
.nowrap {
  white-space: nowrap;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}
.uppercase {
  text-transform: uppercase;
}

.linked {
  cursor: pointer;
}

.font-bold {
  font-weight: 700;
}
.font-normal {
  font-weight: 500;
}
.font-light {
  font-weight: 300;
}
.danger {
  color: $dangerColor;
}
.warning {
  color: $warningTextColor;
}
.default {
  color: $white;
}
.small-text {
  font-size: 12px;
}
.x-small-text {
  font-size: 10px;
}

// close button(circle)
.close-button-alt {
  height: 1.5rem;
  width: 1.5rem;

  cursor: pointer;
  display: inline-block;

  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAC1QTFRFAAAA9/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f3y5vDNQAAAA90Uk5TACeBwu//JbBj93z2VtMVh2gh1gAAAJJJREFUeJxjYGBgYFR2DTESYAADttJQIAhPAHM6Q8FgBojNFQoFC4CcVhgnAqhjKowTmcDAHhq652poaOzp0NACBtHQ0ANvQ0Pv8YSGBjKoAhl8V2MfAAWCGEyBSh68BfJDQ4MZXENBUiCJ0BAGkDmxD0ASoaGoHBRlKAagGI1iKYpzUByK4gVUz6F4GzVAEEEFAOsqYmu1yOFSAAAAAElFTkSuQmCC");
  background-size: contain;
  background-repeat: no-repeat;

  &.primary {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAC1QTFRFAAAAAHm/AHm/AHm/AHm/AHm/AHm/AHm/AHm/AHm/AHm/AHm/AHm/AHm/AHm/h4ZRUgAAAA90Uk5TACeBwu//JbBj93z2VtMVh2gh1gAAAJJJREFUeJxjYGBgYFR2DTESYAADttJQIAhPAHM6Q8FgBojNFQoFC4CcVhgnAqhjKowTmcDAHhq652poaOzp0NACBtHQ0ANvQ0Pv8YSGBjKoAhl8V2MfAAWCGEyBSh68BfJDQ4MZXENBUiCJ0BAGkDmxD0ASoaGoHBRlKAagGI1iKYpzUByK4gVUz6F4GzVAEEEFAOsqYmu1yOFSAAAAAElFTkSuQmCC");
  }
}
// close button (no circle)
.close-button {
  height: 1rem;
  width: 1rem;

  cursor: pointer;
  display: inline-block;

  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABJQTFRFAAAAAAAAAAAAAAAAAAAAAAAA4CsZogAAAAZ0Uk5TVH06AIA8c4GVBwAAAExJREFUeJxjYFQ2BgIjAQYRVxAjxJFBxSXY2NjUxYnB1AUoFOISzAAiQBwGoLArkA1kAIVAsgwglSD1yAyYFFwxTDvcQLgVcEthzgAAR84dfSBOb28AAAAASUVORK5CYII=");
  background-size: contain;
  background-repeat: no-repeat;
}
// titles
.top-title {
  margin: 1.25rem 0;

  font-size: 1.125rem;

  color: $headingColor;
  font-family: inherit;
  font-weight: 700;
  text-align: center;
}

// disable state
.disable {
  background: $disableStateBg !important;

  cursor: not-allowed;
}

// button utility
.btn {
  transition: all 200ms ease-in-out;

  display: inline-block;
  padding: 0.875rem 1.25rem 0.875rem;
  margin: 0.375rem 0;
  opacity: 0.95;
  position: relative;
  border: 0;
  border-radius: 4px;

  line-height: 1.25rem;
  //white-space: nowrap;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  color: $white;
  text-align: center;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  &.disable:hover {
    opacity: 0.85;
    cursor: not-allowed;
  }
}
.btn-warning {
  background: $lightningYellow;
  &:hover {
    background: $lightningOriginal;
  }
}

// inputs utility
input[type="text"],
input[type="password"] {
  transition: all 0.3s;
  height: 2.625rem;
  width: 100%;
  padding: 0.5rem 0.625rem;
  box-shadow: none;
  box-sizing: border-box;
  border: 1px solid $inputBorder;
  border-radius: 0;
}
input[readonly],
input.readonly {
  transition: all ease 300ms;
  border: none;
}
input.error {
  outline: 1px $dangerColor solid !important;
  //margin-bottom: 0.3125rem;
}

.valid-message {
  display: inline-block;
  margin-top: 2px;
  font-size: 0.875rem;
}

// input with transitioned placeholder
.with-input-placeholder {
  position: relative;
  margin-top: 0.75rem;
  input {
    font-size: 0.875rem;
  }
  // icon of value validation
  input + label + span.valid-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    cursor: pointer;
    width: 20px;
    height: 20px;

    background-repeat: no-repeat;
    background-size: cover;
  }
  // icon in input if validation was failed
  input.error + label + span.valid-icon {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAANhQTFRFRERARERARERA8FdX+llY/FlZ/FlZ/FlZ/FhY+1lZ91hYfUpI7FdW+1hY/FlZ/FlZ9VhYYEdE8FdX/FhY/FlZ+FhYakhF/FlY+1lZ/FlZ/FlZ9VhY/FlZ6VdWgUtI+1lZ/FlZ51ZW/FhY2VVU+1lZ+VhY+llZ/FlZ2VVU+1hY/FlZ/FlZ5FZW2FVU+1lY9FhX9VhY/FlZ71dX/FlZ61dX/FlZ/FlZfUpI/FlZ9lhY61dW/FlZ+lhYfUtI+1hYTUVB+lhY/FlZ8ldX+VhY+FhY+VhY+1lZsFBPYX60HgAAAEh0Uk5TAwQFPKDY8/fhtGAHKK//00sFNen4awbpzvz6UOsmB8XdIeQVt4Kg5Ba31f4bFrdCSPYx4Sfv6Ab0VCjanQfPBZr1N4hke60KqtGIpQAAAOZJREFUeJxlketSwjAQRvOlcmmlLQit2ipSFMW2SqtYvKAICrz/G5nsRpyR/ZOTM18mm40Q2C/lpFqsg1q90bSdQ0gplVSu5Xqm/HaHkzjqervqBaFOonVM2xP3VC9RTEk+e3bevyAYJEpafG54iSumkRS4ZrwZ45YpVcnaTppklgvUjbyT96aDiUDjf9IrBJpMpfPwaORUwDZYPhmoZgIO4/PL6xvTXN3+7hN+LPBJsFypPtHu0aO/vitqaB3qKXWC6G8g2WYLGl0YD37dcr0FS4lklGb63vkqNJPXJZFPiumMfgH4ASCzGlIBM7JvAAAAAElFTkSuQmCC");
  }
  // icon in input if validation was successfull
  input.success + label + span.valid-icon {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAF2SURBVDhPvZS/SsRAEMajT6CvoT6Cp6Vy+Y8WFlYi1ieIiE246k4UFHwBXyDZCNcI1oKctTZ2cqeilhY2rvMlo5dNNslZ6AdfkZ2Z397s7J7xrwqCYNIM/TlTuE3YPrdnscbh8WX2zGkr8g4s4T5awpOq3SFirnCnOL1azdhZMCPvpQhSTb/4mdzgMr1o50VK+tABdEZuKRRtYlddYZVRg1rGjJSemb4o7/WLNXn2sC9XeyvpWuR1GZMKk9MPoGjALt+7sv95LLevNpI1OvOBMn1cjXyhzlnYXn9LiYHBOAIKdykbtGNf7l5vKgVVMBgMxhWBh7cteSNP5NFdK/mug8EqMNcyDlu8tRPo6f1OLQzGK2Lcz1CG2YQstA6GoRjSmGBcKow+nwho+NquhMHUbocxI5VdbDtWv/NGTem7pmCD/KunR53Nc7leDK19gnRuT7Wwb6GF9Cmqg2LQgDbsjP33lRWmj+vQDP1l2ImdmcI0/1aG8QV6IToTYOYSIgAAAABJRU5ErkJggg==");
  }
  input.with-placeholder.v-keyboard + label + span.valid-icon {
    right: 3.5rem;
  }
}
// input with virtual keyboard
input.v-keyboard {
  padding-right: 3.325rem;
}

.with-placeholder:placeholder-shown + label {
  transition: all 300ms ease-out;

  position: absolute;
  top: 0.625rem;
  left: 0.75rem;

  color: $xanadu;
  opacity: 0;
  cursor: text;
}

.with-placeholder:not(:placeholder-shown) + label {
  transition: all 300ms ease-out;
  position: absolute;
  top: -1.125rem;
  left: 0;

  color: $white;
  opacity: 1;
}
// form group utility
.form-group {
  margin-bottom: 1rem;
  label {
    margin-bottom: 0.625rem;
  }
}

// table utility
.table {
  text-align: center;
  td,
  th {
    padding: 0.625rem 0.625rem;
  }
}
.light-table {
  background-color: $shark;
  color: $tableLightTextColor;
}
.bordered-table {
  border-collapse: collapse;
  empty-cells: hide;
  td,
  th {
    border: 0.25rem $containersBg solid;
    background-color: $tableBgLight;
  }
}

// list utility
.column-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: -webkit-flex;
  -webkit-flex-direction: column;

  display: flex;
  flex-direction: column;
}

.row-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: -webkit-flex;
  -webkit-flex-direction: row;

  display: flex;
  flex-direction: row;

  justify-content: flex-start;
}

// clearfix utility
.clearfix:after {
  display: table;
  content: " ";
  clear: both;
}

// float utility
.pull-right {
  float: right;
}

.pull-left {
  float: left;
}
.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

// positioning utility
.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

// display utility
.centered {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.vertical-align-center {
  padding: 1.3rem !important;
}

.fullwidth {
  width: 100%;
}

.responsive {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none!important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default !important;
}

.responsive-img-wrap > img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.fullheight {
  height: 100%;
}

.hide-for-mobile {
  @media screen and (max-width: $maxMobileScreenWidth) {
    display: none !important;
  }
}

.hide-for-desktop {
  @media screen and (min-width: $minDesktopScreenWidth) {
    display: none !important;
  }
}

// border utility
.border-dashed {
  border: 1px $bgContainerBgVeryLight dashed;
}
.border-top {
  margin-top: 1.5rem;
  border-left: 0px transparent solid;
  border-right: 0px transparent solid;
  border-bottom: 0px transparent solid;
}

// flexBox utility
.fl-cntr-vcenter {
  align-items: center;
}

.fl-cntr-stretch {
  align-items: stretch;
}

.flex-align-end {
  align-items: flex-end;
}
.flex-align-baseline {
  align-items: baseline;
}

.flex-align-start {
  align-items: flex-start;
}

.justify-around {
  justify-content: space-around;
}

.fl-cntr-start {
  justify-content: flex-start;
}

.fl-cntr-end {
  justify-content: flex-end;
}

.fl-cntr-center {
  justify-content: center;
}

.fl-cntr-btwn {
  justify-content: space-between;
}
