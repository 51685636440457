.logo-jp {
  padding: 0 0.625rem;
  width: 80%;

  background-color: $itemBg;

  .outer-ellipse {
    padding-bottom: 1px;
    width: 100%;

    background-image: url("./../../assets/images/header/logo_left.png"),
      url("./../../assets/images/header/logo_right.png");
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .outer-ellipse-move {
    -webkit-animation: linear infinite alternate;
    animation: linear infinite alternate;
    animation-name: double-move;
    animation-duration: 1s;
    animation-direction: initial;
    animation-iteration-count: 1;
  }

  .super-jackpot {
    font-size: 1.25rem;
    line-height: 2.5rem;
    font-weight: 800;
    text-transform: uppercase;
  }
  .jackpot {
    font-size: 1.125rem;
    line-height: 1.875rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $jackpotColor;
  }

  &:first-child {
    margin-bottom: 0.125rem;
  }
}

@-moz-keyframes double-move {
  0% {
    background-position: 0 0, 100% 0;
  }
  30% {
    background-position: 3% 0, 97% 0;
  }
  45% {
    background-position: 0 0, 100% 0;
  }
  65% {
    background-position: 3% 0, 97% 0;
  }
  100% {
    background-position: 0 0, 100% 0;
  }
}

@-webkit-keyframes double-move {
  0% {
    background-position: 0 0, 100% 0;
  }
  30% {
    background-position: 3% 0, 97% 0;
  }
  45% {
    background-position: 0 0, 100% 0;
  }
  65% {
    background-position: 3% 0, 97% 0;
  }
  100% {
    background-position: 0 0, 100% 0;
  }
}

@keyframes double-move {
  0% {
    background-position: 0 0, 100% 0;
  }
  30% {
    background-position: 3% 0, 97% 0;
  }
  45% {
    background-position: 0 0, 100% 0;
  }
  65% {
    background-position: 3% 0, 97% 0;
  }
  100% {
    background-position: 0 0, 100% 0;
  }
}

@media screen and (min-width: $maxMobileScreenWidth) {
  .super-jackpot-label-text-shadow {
    -webkit-animation: blink 2s linear infinite;
    -moz-animation: blink 2s linear infinite;
    -ms-animation: blink 2s linear infinite;
    -o-animation: blink 2s linear infinite;
    animation: blink 2s linear infinite;
  }

  .jackpot-label-text-shadow {
    -webkit-animation: golden-blink 2s linear infinite;
    -moz-animation: golden-blink 2s linear infinite;
    -ms-animation: golden-blink 2s linear infinite;
    -o-animation: golden-blink 2s linear infinite;
    animation: golden-blink 2s linear infinite;
  }
}
