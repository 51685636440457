// stake bet block
.stake-operation-block {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  padding: 1.125rem 1.25rem 0;

  background-color: $bgContainerBgLighter;

  .stake-status {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    padding: 0 0.625rem;
    font-size: 1.125rem;

    .flex-row {
      flex-direction: row;
      [class*="flex-col"] {
      }
    }
  }

  .toggle-stake-mode {
    .stake-mode-indicator {
      display: inline-block;
      padding: 3px 5px 5px 25px;
      margin: 0 5px;

      cursor: pointer;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAMAAABFjsb+AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAGZQTFRFAAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////lZ9pqAAAACJ0Uk5TACqO1/jWBpT9/5gJu6BAELyV8Cv8j/kg89SQLO+fP9j69MM1o2sAAACISURBVHiclVDLEoIwDCwUgVWbyFNpAfX/f9IJ2Mk41oN72cxOHrsx5i9kuS2KQ56pUlY1NtTHU5TOgCNmcsCl3LUGLe0Vtai2outBcQuh74QHOF3tMAhZbZNGKzSCVWOMX9oVt8TslLjhhcKnl/D2PEfPMxrNtqzM66LZUj+QfP7+eE4+mF94AWXtBy29dreIAAAAAElFTkSuQmCC");
      background-repeat: no-repeat;
      background-position: 0 0;

      text-transform: uppercase;
      font-size: 1rem;
      &.active {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAMAAABFjsb+AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAGlQTFRFAAAA/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////ButdgAAACN0Uk5TACqO1/jWBpT9/5gJu7yVK/ywQMCP+RDzUNTfkCxPP7/Y+vR9K/BGAAAAiUlEQVR4nJWQ2w6CMBBEC0WgqFwqLEKrqP//ke5QSk2AB+fp5CSTvQjxV6JYJskpjoJJs1zNyYuzVxe15po6V6qfZLOqasZGC6FvDHUF10K5BmQLkAzaOc0oAR3DMo0YO+8ouN5376E7+BkjrTMMwGKX8UH0nLCL3e5cHt+29wPcZ17vz2Ds4TO/f98Ivyx4Va0AAAAASUVORK5CYII=");
      }
    }
    @media screen and (max-width: 1440px) {
      .stake-mode-indicator {
        margin: 0;
      }
    }
    @media screen and (max-width: 520px) {
      .stake-mode-indicator {
        padding: 5px 3px 5px 23px;
        margin: 0 1px;
        font-size: 1rem;
      }
    }
    @media screen and (max-width: 374px) {
      .stake-mode-indicator {
        font-size: 0.75rem;
      }
    }
  }
  // input with stake amount
  .stake-amount {
    flex-direction: row;
    padding: 0.75rem 0.5rem 0.75rem 0.75rem;
    background: $containersBg;

    &-label {
      display: inline-block;

      padding-left: 1.125rem;
      margin-top: 0.625rem;

      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAQCAMAAAAYoR5yAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAFdQTFRF9/f39/f39/f3AAAA9/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f3qTi5hAAAAB10Uk5TvfVeAOb/cyzkiwEe2aADFcq0Cgu6xBAGqNMcktczJ8h2AAAAUUlEQVR4nF3ORw6AMAxE0YD5lFBC7/c/J5JBcoRXb2F7xrkklXdcBvnHooTKK6VuoO2UEnoYRqVMMyyrUrYdjlMp/oL7T1uwM3tmEVFwVMdKPpTZBKHRcFyVAAAAAElFTkSuQmCC");
      background-repeat: no-repeat;
      background-position: 0 30%;

      font-size: 1.25rem;
    }
    &-input {
      position: relative;

      input {
        padding-left: 2.125rem;
        padding-right: 3.75rem;

        text-align: right;
        font-size: 1.25rem;
        height: 2.75rem;
      }
      input.v-keyboard {
        padding-right: 6.25rem;
        &+.input-currency {
          right: 3.5rem;
        }
      }

    }
    .input-currency {
      position: absolute;
      top: 25%;
      right: 1rem;

      margin-top: -0.0625rem;

      color: $black;
      font-weight: 400;
      font-size: 1.25rem;
      &.danger {
        color: $dangerColor;
      }
    }
    .close-button-alt {
      position: absolute;
      top: 25%;
      left: 10px;
    }


  }
  // increments list
  .increments-list {
    margin-top: 1px;
    padding: 0.625rem;

    background: $containersBg;
    li + li {
      margin-left: 3px;
    }

    li {
      transition: background-color ease-out 200ms;

      padding: 1rem 0.375rem;
      width: 16.67%;

      cursor: pointer;
      background: $bgContainerBgVeryLight;

      text-align: center;

      &:hover,
      &.active {
        transition: background-color ease-out 200ms;

        background: $activeStateColor;
      }
    }
  }

  .disable {
    transition: all ease-out 300ms;

    position: relative;

    .disable-block {
      transition: all ease-out 300ms;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      cursor: not-allowed;
      width: 100%;

      background: rgba(60, 63, 65, 0.7);
    }

    .disable-button {
      cursor: not-allowed;
    }
  }
}
