@import "./../variables";

.calculator {
  height: 103vw;
  width: 100%;
  box-shadow: 0 2rem 4rem var(--shadow);
  background-color: $modalBackdropBg;
  overflow: hidden;

  &.isKeyboard {
    height: 48vh;
  }

}

.calculator-screen {
  height: 6vh;
  width: 100%;
  padding: 0 1rem;
  overflow: hidden;
  white-space: nowrap;
  color: $shark;
  background-color: $modalBackdropBg;
}

.result-screen {
  height: 5vh;
  font-size: 2rem;
  font-weight: 400; /* To display the value at the bottom-right of the container */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  input[type='password'] {
    height: 5vh;
    margin: 0;
    padding: 0;
    width: 100%;


    border: none;
    box-shadow: none;
    background-color: $modalBackdropBg;

    font-size: 2rem;
    text-align: right;
  }
}

.keypad {
  height: 35vh;
  width: 100%;
}

.keypad-row {
  height: 7vh;
  width: 100%;
  display: flex; /* To control the dimension */
  color: white;
  background-color: $nevada;

  &.small-height {
    height: 5vh;
    background-color: #687178;
  }
}

.keypad-button {
  width: 100%;
  height: 100%;
  font-size: 2rem;
  font-family: inherit;
  color: white;
  background-color: $nevada;
  border: 1px solid transparent;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;

  &--dark {
    background-color: $nevadaDark;
    transition: all 0.2s;
    //border: 0;
    //border: 1px solid $nevadaDark;
    border-radius: 6px;
  }

  &:hover {
    color: var(--color-light-white);
    background-color: $deepCerulean;
  }
  &:active {
    transform: scale(0.95);
  }

  &--large {
    color: var(--color-white);
    background-color: $deepCerulean;
    transition: all 0.2s;
  }

  &--large:hover {
    background-color: var(--color-white);
    color: $deepCerulean;
  }
  &--space {
    //height: 5vh;
    padding-bottom: 0.4vh;
    border: 1px #697278 solid;
    border-radius: 6px;
    background-color: #575e6394;

    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.7);
    //border-top: 1px #fff solid;
  }
}

@media screen and (max-width: $maxMobileScreenWidth) {
  .keypad-button {
    font-size: 1.125rem;
    padding: 0;
  }
}


@media screen and (max-height: 800px) {
  .calculator {
    height: 48vh;
  }

  .calculator.isKeyboard {
     height: 48vh;
   }
}
