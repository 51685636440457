@import "./../variables";

@import './Gamelist.scss';
@import './LoginMenu.scss';
@import './AdditionalInformation.scss';
@import './JackpotLogo.scss';
@import "./HeaderSlideMenu";

@import './MobileHeader.scss';



.header {
  padding: 0.375rem 0.625rem;
  margin-bottom: 0.375rem;

  background-color: $containersBg;
  @media screen and (max-width: $maxMobileScreenWidth){
    margin-bottom: 0;
  }

}