@import "./../variables";

// tooltip styles
.__react_component_tooltip.app-tooltip {
  z-index: 99999;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 0;

  // element like tooltip
  &.custom-danger-tooltip {
    position: absolute;
    top: 3px;
    right: -10px;

    color: $white;
    background: $errorTooltipBg;
    border: 1px solid transparent;
  }

}

// element like tooltip
.custom-danger-tooltip {
  transition: opacity 0.3s ease-out;

  position: absolute;
  top: -3px;
  left: 100%;
  z-index: 99999;

  display: none;
  padding: 1rem 0.875rem;

  border-radius: 0;
  background: $errorTooltipBg;
  border: 1px solid transparent;
  pointer-events: none;

  text-align: center;
  color: $white;
  font-size: 13px;

  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
  }

  &.place-right {
    margin-left: 10px;
    width: 280px;
    &:before {
      left: -8px;
      top: 50%;
      margin-top: -5px;

      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 8px solid transparent;

    }
    &:after {
      left: -6px;
      top: 50%;
      margin-top: -4px;

      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right-color: $contessa;
      border-right-style: solid;
      border-right-width: 6px;
    }
  }

  &.show {
    opacity: 0.9;
    display: block;
  }
}

