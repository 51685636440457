@import "./../variables";

.bets-types {
  position: relative;

  background-color: $containersBg;
  &.exit {
    display: none;
  }

  .tabs > nav.tabs-titles {
    padding-right: 3.175rem;
  }

  .titles-fullscreen {
    display: none;
    position: absolute;
    right: 0;
    z-index: 1;

    height: 3rem;
    width: 2.5rem;

    background-image: url("./../../assets/images/bets/tabs-fullscreen-icon.png");
    background-position: center;
    background-repeat: no-repeat;


    cursor: pointer;

    &.exit {
      display: none;
    }
    &.close-second-screen {
      margin-top: 0.25rem;
      margin-right: 0.25rem;

      background-image: url("./../../assets/images/video/fullscreen-icon.png");
      background-size: cover;

    }
  }
}

.bets-scrollbar {
  transition: all 360ms;
  transition-property: min-height;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.accordion-table-wrap {
  padding: 0 1.25rem;
}
table {
  //table-layout: fixed;
}
// clickable cell for make bet
.table tr > td {
  transition: all ease-out 300ms;

  cursor: pointer;

  &:hover {
    transition: all ease-out 300ms;
    background-color: $white;
    box-shadow: inset 0px 0px 4px $tableCellActive;
  }

  &.active {
    background-color: $deepCerulean;

    color: $white;
  }

  &.unavailable {
    background-color: $inactiveBgColor;
    color: $inactiveBgColor;
    box-shadow: none;
    cursor: default;
  }
}

// cell of table but no table styles applying with
.table tr > td.no-table-cell,
.table tr > th.no-table-cell {
  padding: 0 0;

  background-color: $containersBg;

  color: $headingColor;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;

  &:hover {
    transition: none;
    cursor: default;
    box-shadow: none;
  }

  span[class*="event-win-item"] {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.first-second-label {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEcAAAAbCAYAAAAu/JKTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgB7Ze9bhNBFIXHduQfGjCP4D6VeQQHKQJKuwFaKKELJaJKGqBNCh4grhANEpbCAyQF7u2aAsWi8Z9km++YuWhjedkNMcFr9kjjO3dmdtZz9p67d51LkSLFCjAajfaGw+FsMBgcZ90GwA5kTb5bARJPDk94fzab7QfH5K+CoE2InCf6yWQyjWKxmKF7JH86nVbdFZF4ckql0m2RUigUmvKJmjNZyOrJKrIsh2A7Xnqd8Xhc9evL+J9MkqBme29EzjHooJAyl1M2mz0KzjFex1S8WyGy5lKEkENMLbD0+skhB2zz9JQst91fgEUA3QpEvMjn82cL801FmOTnfZPdnAyTJeMHds2cHIWYws+HnkKr7BIE/ndFxHDAqohBYgdLlnX1w3zXBnyklbE9kyUR17P5LWV1hRiL3GUhUrn2VJtL++4fQMTw3y1iGnbIOFBe4vqeCMLWOEOL85SNC5NVKxhOSYIRoz5nOA7WOzEV0LJ9dI3lLCGrEERrO7Sl5FjI2g3xT4m2upqixm8svc/sDXCduGoK4NxPnSfI79dcepNljIsMO7jG8c/V1NeYJ+w87ObMP5NdTMiMP3drjq2oBYoK2clksqcaIphbICfqcpH+BlK+0W3bGP5Dxl/TfevWGJGvcrJ3Q6SoTlBZ7qPr0MUE13zAvEOC9+VjH8j348nAMll5Gf36TpGVhExmcWTFHjeYP6FNJStvTzTukoKwnKNS2+eZivlGjk/Wwa/hesjeN/v9flvkyMp3CUAsWSEpyarjiVNd0FAFSv7pquiK2oM131m7Q/cj9q58l2L9oLekpE0Ev+dBF12Ki4CcR7Qx7TME3Qpbd/lvhgThd0Uppck9zEvI+YLUd5H618U1G02OXgAxl7Z8TryAyCIwycjlcnfC5oicXcwrIqcNMY9dip9QhR4n5/x3WHhblVyKP8MPnEeHjUp6+1QAAAAASUVORK5CYII=");
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
  height: 9.7%;
  width: 13%;

  &.ru {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEcAAAAbCAMAAAAZImKhAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAMxQTFRFAAAA9/f3+fn5+Pj49/f3////////////////9/f3+fn5+Pj49/f3////////////9/f39/f3////////////+Pj4+vr6////+fn59/f39/f3////9/f3/f39////////////////+/v7////////////+Pj4////////+Pj4+Pj49/f39/f3////9vb29/f39/f39/f3////9/f3////9vb2////9/f3////////9/f39/f39/f3////9/f39vb29/f39/f39vb2////1ihKjwAAAER0Uk5TACp3/4hSf1rM7lWZqoCcW2ZDR/8R3TPVLf/MlSKNwLYr6mYwv1W7qhxvcP5bZbH5eXoKvw/SF7cYCFz6wBB+z7t80AmdVsapAAABHUlEQVR4nN3RWVODMBQF4GMvorWNVQtYtNYqrg1S7eKudfn//8mQNkxg4jgBnjxPyUO+JOcC/zJr9kcaRNSofrNDpEHr7sZmOadJW2hRW23Zdqf8m3aohd104brAXrcs4/kUrBwG7P/l9MKemTlI61EOO1w5gdMmz8LpSwZHg8ExGBueYOmkY1ROvn2z0/dF0SKnURQJ5+xcORdO5uTbNzu+nDt58l/MvQRUP55yCu2bnWbO6V6ZHL39a+mMDNJv0Z2sffBYODG3dG4K7QPJ+Da8GyeWTlRoH5hMecinE0tHLnLtYzYP5zMLRnP09oH7h0cbpkJGPHmqBXp+eX2rJgTLvPNFtc+HWT7qeM8n/6pjGHHlfmTEvL5rYPT8AN2OGbbxi/JmAAAAAElFTkSuQmCC');
  }
}

// for main bet table
.table.main tr > th {
  padding: 0.625rem 0.375px;
}

// for Trifecta table
// first cell in row
.table.trifecta tr > td.no-table-cell {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  width: 100px;

  text-align: right;
}
// each cell with name of column
.table.trifecta tr > th.no-table-cell {
  padding: 10px 0 0;
  span[class*="event-win-item"] {
    max-width: 1.5rem;
    height: 1.5rem;
  }
}

// for two-way-bets table
.table.two-way-bets tr > th.no-table-cell {
  padding: 15px 10px 5px;
}

// block under table
.btns-block {
  margin-top: 1rem;
  li {
    transition: background-color ease-out 300ms;

    flex: 1 1 49%;
    padding: 0.75rem;

    background-color: $inactiveBgColor;
    cursor: not-allowed;

    text-align: center;
    font-size: 1rem;
    font-weight: 600;

    // if 3 cell in diffs rows were selected than change bg of APPLY btn
    &.active {
      transition: background-color ease-out 300ms;

      cursor: pointer;
      background-color: $activeStateColor;
    }
    // if at least one cell was selected than change cursor of RESET btn to pointer and add hover effect
    &.available {
      transition: background-color ease-out 200ms;

      cursor: pointer;
      &:hover {
        transition: background-color ease-out 200ms;

        background-color: $activeStateColor;
      }
    }
    & + li {
      margin-left: 5px;
    }
  }
}

// status block for showing bet result numbers
.status-block {
  background-color: $tableBgLight;
  color: red;
  height: 30px;

  li {
    width: 25px;
    & + li {
      margin-left: 1%;
    }
  }
}
.event-item {
  width: 200px;
}

.show-betslip-button {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 3.1rem;
  line-height: 2rem;
  width: 100%;
  margin: 0;
  padding: 0.625rem;
  //z-index: 10000;

  &.preloader-layer {
    z-index: $preloaderLayer;
  }
  span {
    display: inline-block;
    padding: 0.1rem;
    width: 1.7rem;
    height: 1.7rem;

    background-color: $white;
    border-radius: 50%;
    vertical-align: middle;

    text-align: center;
    font-weight: 600;
  }
}

.show-result-history-button {
  position: fixed;
  bottom: 3.1rem;
  left: 0;

  margin: 0;
  //padding: 0.625rem;
  height: 3.5rem;
  width: 100%;
  //z-index: 10000;

  &.preloader-layer {
    z-index: $preloaderLayer;
  }

  color: white;
  background: #002840
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAXCAMAAAAm/38fAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAASZQTFRFAAAA9/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f3z9m29gAAAGJ0Uk5TAAE6iL3d5ePGk0oFOLrLUXXk36Z8bHec2JcJeobZbg4HXdCrYx8QqZSLvkzeITmQEtEERgiewnTh01RloK8o4kOADMQvcxoqkYVZSSspE1OdcBG1CwN7fW03uM9Xg7zHlk4mJLIrAAABG0lEQVR4nF2Sh1LCQBCGN0r+BFFUEImiQNCIjYio2NAgGuy9d33/l/BKSHLuzM3t7Hfbj0iK1tef0GGYyYEUxWVwCD1JD0dmbWQUyGTHcuN5i6GJSW4ssDMFTBdL4k3ZrgAznKBAs0DCCd3nqsA8B1jQsViK5UsxssQBsFzjBhcrktRXYTYEyJMCyAbWBEBOBes6UxGSCNAGmmRJsRWwia1YNS62dzSp7gIxUG8Be/uBh6dMrG3CO+iIHIdER77f7ZGyn/GORVUnRA5wGjk5Z7KPc3Zl4V0oEVnn6Qa7Lw2YV/9mdS20ItCsKdO9kaqWBPTbO5me7+P+IXj0+MQ3WH1+kRtsdaK4r2/hzt8/NKUU9/PLgvH98xv8kj+CBB7a4e5SJAAAAABJRU5ErkJggg==")
    no-repeat 5% 50%;

  justify-content: space-between;
  display: flex;

  span {
    margin-left: 12%;
    font-weight: 600;
    line-height: 2rem;
  }
}

.history-button {
  position: absolute;
  bottom: 0;

  &-divider {
    position: absolute;
    height: 0.2rem;
    width: 100%;
    bottom: 0;
    left: 0;
    background: rgb(123, 165, 191);
    transition: all 260ms ease 0s;
  }

  &-svg {
    color: #ffffff;
    margin-right: 0.937rem;
    width: 1.25rem;
    height: 2rem;
    transition: transform 260ms cubic-bezier(0, 1, 0, 1);
    transform: rotate(270deg);
  }
}

.bets-preloader {
  position: relative !important;
  opacity: 0.6 !important;
}

.event-odds-item {
  position: relative;
  .countdown-container {
    position: absolute;
    top: 1.2rem;
    right: 1.3rem;
    z-index: 1;
    font-weight: 600;
  }
}


.show-preloader {
  pointer-events: none;
}
.show-preloader .pre-loader-container {
  background: transparent;
  opacity: 1;
  img {
    position: absolute;
    top: 10px;
    right: 22px;
    width: 30px;
  }
}
//
@media screen and (max-width: $maxMobileScreenWidth) {
  .bets-types {
    height: 100%;
    max-height: 100%;

    .accordion-table-wrap {
      padding: 0 1rem;
    }

    // for main bet table
    .table.main tr > th.no-table-cell:first-child {
      width: 30px;
    }
    .table {
      table-layout: fixed;

      tr > td {
        padding: 2px 0;
        font-size: 12px;
      }
    }
    .table.trifecta,
    .table.two-way-bets {
      tr > td {
        padding: 6px 0;
      }
    }

    .table.trifecta tr > th.no-table-cell:first-child {
      display: none;
    }

    .table.trifecta tr > td.no-table-cell {
      display: none;
      width: 58px;
      padding-left: 0 !important;
      padding-right: 10px !important;
      padding: 5px 0 0;

      text-align: right;
      font-size: 12px;
    }
    // each cell with name of column
    .table.trifecta tr > th.no-table-cell {
      padding: 10px 0 0;
      span[class*="event-win-item"] {
        max-width: 1.5rem;
        height: 1.5rem;
      }
    }

    .tabs > nav.tabs-titles {
      padding-right: 0;

      .tabs-titles-item {
        padding-left: 5px;
        padding-right: 5px;
        font-size: 13px;
      }
    }
  }
  .first-second-label {
    background-size: 85%;
    height: 9%;
  }
  // .smaller-font-size .bets-types .tabs > nav.tabs-titles .tabs-titles-item {
  //   font-size: 10px;
  //   letter-spacing: 0px;
  // } 
}

@media screen and (max-width: 550px) {
  .bets-types {
    .accordion-table-wrap {
      padding: 4px 0;
    }
    .bets-types .tabs > nav.tabs-titles .tabs-titles-item {
      font-size: 12px;
    }
   
    .table tr > td {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .smaller-font-size .bets-types .tabs > nav.tabs-titles .tabs-titles-item {
    font-size: 10px;
    letter-spacing: 0px;
  } 
}

@media screen and (max-width: 359px) {
  .show-result-history-button {
    padding: 11px;
    span {
      font-size: 12px;
    }
  }

  .show-betslip-button {
    font-size: 12px;
    padding: 8px;
  }
}

@media screen and (max-width: 374px) {
  .bets-types .tabs > nav.tabs-titles .tabs-titles-item {
    font-size: 12px;
  }
  .smaller-font-size .bets-types .tabs > nav.tabs-titles .tabs-titles-item {
    font-size: 10px;
    letter-spacing: -1px;
  } 
  .bets-types .table.trifecta tr > td {
    padding-left: 0;
    padding-right: 0;
  }
}
