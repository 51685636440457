@import './../variables';

$modalBackdropZIndex: 9005;

.modal-backdrop {
  transition: opacity ease-out 300ms;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;
  opacity: 0;

  background: $modalBackdropBg;

  &.active {
    z-index: $modalBackdropZIndex;
    opacity: 0.6;
  }
}

.modal-wrapper {
  transition: all ease 300ms;

  position: absolute;
  top: -2000px;
  z-index: -1;

  opacity: 0;
  display: none;

  &.active {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $modalBackdropZIndex + 1;

    display: block;
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}

.modal {
  transition: all ease 300ms;
  transform: translate(50%, -50%);

  position: absolute;
  top: -999px;
  right: 50%;
  z-index: $modalBackdropZIndex + 1;

  opacity: 0;

  padding: 20px 40px;
  max-width: 500px;
  width: 90%;

  background-color: $containersBg;

  &-header {
    text-align: center;
  }

  &-main {
    margin-bottom: 15px;
  }

  &.active {
    transition: all ease 300ms;
    top: 50%;
    //z-index: $modalBackdropZIndex + 1;
    opacity: 1;
  }

  &-add-btn {
    position: absolute;
    top: 10px;
    left: 10px;

    cursor: pointer;
    color: $white;
    font-weight: 300;
    font-size: 0.875rem;

    &:hover {
      transition: all ease-out 300ms;
      filter: drop-shadow(0 0 0.25rem $actionBtnBg);
    }
  }
  &-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;

    cursor: pointer;
    color: $white;
    font-weight: 300;
    font-size: 0.875rem;

    &:hover {
      transition: all ease-out 300ms;
      filter: drop-shadow(0 0 0.25rem $actionBtnBg);
    }
  }
  &-keyboard-btn {
    position: absolute;
    top: 0.6rem;
    right: 0.5rem;
    cursor: pointer;
    width: 36px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/images/keyboard/keyboard.png");
    &:hover {
      transition: all ease-out 300ms;
      filter: drop-shadow(0 0 0.25rem $actionBtnBg);
    }
  }

  &-betslip-keyboard-btn {
    position: absolute;
    top: 1.4rem;
    left: 4.3rem;
    cursor: pointer;
    width: 25px;
    height: 15px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("../../assets/images/keyboard/keyboard.png");
    &:hover {
      transition: all ease-out 300ms;
      filter: drop-shadow(0 0 0.25rem $actionBtnBg);
    }
  }
}

@media screen and (max-width: 520px) {
  .modal {
    padding: 15px 10px;
  }
}
