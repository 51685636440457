@import "./../variables";

.rotation-window-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $containersBg;
  text-align: center;
  opacity: 0.98;
  z-index: $gameSelectLayer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.hideWindow {
    display: none;
  }

  &.transparentAbsoluteWindow {
    position: absolute;
    opacity: 0.5;
    z-index: $gameSelectLayer;
  }

  &.show-in-parent {
    position: absolute;
  }

  h1 {
    position: absolute;
    top: 65%;
    left: 50%;
    display: inline-block;
    transform: translate(-50%, -50%);
  }

  .rotation-image {
    width: 15%;
    top: 50%;

    &.toTheRight {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}
