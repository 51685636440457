@import "~react-datepicker/dist/react-datepicker.css";

.bet-content {
  margin-top: 1.25rem;
  line-height: 1.312rem;
  padding: 0.312rem 1.25rem;
  font-size: 1rem;
  color: #f8f8f8;
  &-row {
    flex-direction: row;
  }
}

.bet-content>.bet-content {
  margin-left: 3rem;
  padding-right: 0;
  width: 90%;

  border-bottom: 1px solid #98a4ad;
  &:last-child {
    border-bottom: none;
  }
}

.bet-content-divider {
  display: block;
  margin: 0.5rem 0;
  height: 1px;
  width: 100%;
  background: #98a4ad;
}

.bets-filter-block {
  & + .bets-filter-block {
    margin-top: 1.875rem;
  }

  // list of bets filter
  &-list {
    &-item {
      position: relative;
      padding: 0.275rem 2.25rem 0.275rem 1.25rem;
      color: $black;
      font-size: 1.125rem;
      line-height: 1.5rem;

      .close-button {
        position: absolute;
        right: 3.6rem;
        visibility: hidden;
        top: 44%;
        opacity: 0.7;
        background-size: 74%;

        &.active {
          visibility: visible;
        }
      }

      .status-filter {
        display: inline-block;
        height: 100%;
        width: 49%;
        margin: 2%;
      }
      .date-filter {
        position: relative;

        display: inline-block;
        height: 100%;
        margin-left: 0.6rem;
        width: 43%;
        bottom: 0.1rem;

        &::after {
          content: "";

          position: absolute;
          top: 0.25rem;
          left: -0.625rem;

          display: block;
          width: 0px;
          height: 90%;

          border-left: 1px #9ca4a9 dashed;
        }

        label {
          margin-bottom: 1px;
          display: inline-block;
          color: #f8f8f8;
          font-size: 1rem;
          font-weight: 400;
        }

        .date-picker-input {
          border-radius: 0.25rem;
          border-color: transparent;
          height: 2.6rem;
          width: 13.2rem;
          margin-top: 0.4rem;
          padding: 0.7rem;
          font-size: 1.125rem;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
            "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
            "Helvetica Neue", sans-serif;
        }
      }
    }
  }
}

@media screen and (max-width: $maxMobileScreenWidth) {
  .bets-filter-block-list-item {
    padding-bottom: 1rem;
    .status-filter {
      width: 100%;
    }
    .date-filter {
      width: 100%;
      margin-left: 0.5rem;
      &::after {
        display: none;
      }
      .date-picker-input {
        border-radius: 0.25rem;
        width: 100%;
      }
      .react-datepicker-wrapper {
        width: 100%;
      }
    }
  }
}
