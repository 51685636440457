.games-list {
  &-disable-click {
    pointer-events: none;
    opacity: 0.5;
  }
  &-item {
    position: relative;

    //height: 75px;
    height: 4.75rem;
    flex: 1 1 9.375rem;
    margin-right: 0.125rem;
    cursor: pointer;

    background-color: $itemBg;
    background-repeat: no-repeat;

    color: white;
    text-align: center;

    &.DOG_6,
    &.DOG_8,
    &.DOG_6_WEB,
    &.DOG_8_WEB {
      background-image: url("./../../assets/images/header/dogs.png");
      background-position: center 1.125rem;
      .games-list-item-name span {
        color: $activeStateColor;
      }
    }

    &.HORSE_6,
    &.HORSE_8,
    &.HORSE_6_WEB,
    &.HORSE_8_WEB {
      background-image: url("./../../assets/images/header/horse.png");
      background-position: center 0.25rem;
      .games-list-item-name span {
        color: $dangerColor;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .games-list-item-name {
      position: absolute;
      width: 100%;
      bottom: 0.375rem;
      left: 0;
    }

    &:hover {
      transition: all ease-out 400ms;
      box-shadow: 0 0 0.375rem $actionBtnBg;
    }

    &.active {
      cursor: default;
      &.DOG_6,
      &.DOG_8,
      &.DOG_6_WEB,
      &.DOG_8_WEB {
        background-image: url("./../../assets/images/header/dogs_active.png"),
          linear-gradient(
            180deg,
            rgba(48, 52, 55, 1) 0%,
            rgba(37, 40, 42, 1) 55%,
            rgba(23, 25, 26, 1) 100%
          );
        background-position: center 1.125rem, 0 0;
      }

      &.HORSE_6,
      &.HORSE_8,
      &.HORSE_6_WEB,
      &.HORSE_8_WEB {
        background-image: url("./../../assets/images/header/horse_active.png"),
          linear-gradient(
            180deg,
            rgba(48, 52, 55, 1) 0%,
            rgba(37, 40, 42, 1) 55%,
            rgba(23, 25, 26, 1) 100%
          );
        background-position: center 0.25rem, 0 0;
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .games-list {
    &-item {
      background-size: 100%, 100%;
    }
  }
}
