@import "./../variables";

.pre-loader-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $containersBg;
  text-align: center;
  opacity: 1;
  z-index: 909999901;
  display: flex;
  align-items: center;
  justify-content: center;

  &.hideLoader {
    display: none;
  }

  &.transparentAbsolutePreLoader {
    position: absolute;
    opacity: 0.5;
    z-index: 9000;
  }

  &.transparentBackground {
    background: transparent;
  }

  &.show-in-parent {
    position: absolute;
  }

  h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    margin: 0 -50% 0 0;
    display: inline-block;
    transform: translate(-50%, -50%);
  }

  img {
    width: 5%;
    top: 50%;
  }
}
