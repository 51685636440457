$nevada: #687177;
$nevadaDark: #575e6394;
$shark: #26292b;

$woodsmoke: #17191a;

$deepCerulean: #0373b5;
$red: #ff0000;
$crimson: linear-gradient(#d1363d, #c9131b);
$iron: #d2d2d3;
$lightningYellow: #e29f01;
$lightningOriginal: #ffba00;
$lightningYellowSunRay: #f9cf32;
$mediumTurquoise: linear-gradient(#4bbad3, #1ca8c8);
$cerulean: linear-gradient(#2d9db7, #24b0d0);

$white: #ffffff;
$black: #000000;

$trout: #495057;

$lochmara: #0079bf;
$alabaster: #f8f8f8;
$alabaster2: #f7f7f7;

$rollingStone: #757b7f;

$historyItemName: #575757;

$outerSpace: #313538;
$capeCod: #3c3f41;
$nevada: #697278;

$caribbeanGreen: #0accab;
$jasper: #d2533f;
$brass: #8c8a0e;
$contessa: #BE6464;
$xanadu: #757775;

$greenPea: #1a4e45;
