.login-menu {
  height: 100%;
  &-item {
    font-size: 1.125rem;
    line-height: 1.25rem;

    font-weight: 700;
    text-transform: uppercase;

    // wallet amount
    &.credits-block {
      justify-content: space-between;
      display: block;
    }
    &.add-credits {
      cursor: pointer;
      margin-right: 1.25rem;
      margin-bottom: 0.5rem;
      position: relative;
      background-color: $headingColor;
      span {
        line-height: 1.875rem;
        color: $woodsmoke;
        display: block;
      }
    }
    &.credits {
      cursor: pointer;
      position: relative;
      margin-right: 2.25rem;
      span {
        color: $jackpotColor;
      }
    }

    &.logout span,
    &.menu span,
    &.username span {
      margin: 0 auto;
      display: block;
      width: 2.25rem;
      height: 2.875rem;
      margin-bottom: 0.3125rem;

      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    // Logout icon
    &.logout {
      cursor: pointer;
      font-size: 0.875rem;
      span {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAuCAMAAABpjHt/AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAATtQTFRFAAAA9/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f3hSIPqwAAAGl0Uk5TABSo+QHE/ywrQFfW9stDZvoyUP2aI/Gqq8VlY7EDYgbixgrHZO4cZyHcBDFwAtLRoBggwR8Z1RPsp6mMjYD8juvK19jJG3Md3t/Da14FzM2+C8DwaGlJ2hJs21/IkW5gb+kXrq0PveH1iQpYEQAAAedJREFUeJztk+dCwjAUhaOUOBgyBBcoIigiKCKKEyegLHedOFDQ938C29ykLSStPoD3T86552va3jYIcdXXb7P19/F9viQ7Vsr+F3QAkxr8AzoE6NA/apYPO5wutwh1u5yOYSM5ooYeL496Paoe0Umfn6SjPDpKtN/HyEAQ0jEeHQcTDAApTYDHkzw6SaMJidgpakNhHg2HaDitupkImNmoaALRObCRGcXEQMfnxXOdj4OPKXoWpDaQ3k+wQG+KUALUYpJFXmgsMZ9chEaC3T+lDTm9rPqVtNZIsSfIgFjVP0h2DeO1nO7XgcggF1k3kLHy+S67QRAX2iTrFrKobYJsIvj8O1boLvwICB5kzwrdAwYVft91nyAFdEDWbSt0jCAH6JCsR1boMUFO2FyL5mSRzZX+giVztMR+wzKI06QZmTwDooxQBVTVDK1CXlFkDWQwISYT9ODVFF2np6ARFZHRc0gjdaRviy8E7OUF1jdVTuwVtY1cL5lt0OhKog16fPD1TdgIhm+vaRDXNpH9tIXvqvesmX94ZF2/rF//hLUqPDdfXl/fms8FvfdkvFfNg03LU+t+Afe7Gfnu7n3ZVkhMhlqCacvLPLgiC0B1wPKHzcjZPmVJTKrV9u12vpwYf391dnzt7uwHk3k57l8VzKQAAAAASUVORK5CYII=");
      }

      &.inactive {
        opacity: 0.8;
        cursor: not-allowed;
        &:hover {
          transition: none;
          filter: drop-shadow(0 0 0 transparent);
        }
      }
    }
    // Menu icon
    &.menu {
      cursor: pointer;
      font-size: 0.875rem;
      span {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAlCAMAAAADS4u8AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEVQTFRFAAAA9/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f36NXTBQAAABd0Uk5TACy1+f8u9Ln7sibsIaPm8C1xgAKXduFGTQUKAAAAfElEQVR4nO2VSQ6AIBAEGxkFXEHF/z9VjQ+g+26dK2QyhAJwnbcmvnNAP7TFl6FH4EyzgMiqEcSgHx6JVRPGiTOnEZiXdWuyLjN+HnLZm5T8iMfJXcF5oLIXW3Gx6gXWNFNOFWYVNiDs9Ud4sUIHhLoIzRJKKPRVqDb/F9yhXhj5AJyy4QAAAABJRU5ErkJggg==");
      }
    }
    &.username {
      font-size: 0.8rem;

      &:hover {
        transition: none;
        filter: drop-shadow(0 0 0 transparent);
      }
      span {
        height: 1.5rem;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADDklEQVR4nO2aO2tUURSF1x5CCCEECRpEJChYhCBptIgPRIJYWIhYWoiIBKIWImIpiKRKISJBsLBQ9A+IYKNiCjEIBhFBi5AMiPggYkQ0PjKfxZ0LwzhqZmafORPmfj9g3bX23Zxzzz5XysjIaGWsUQ8COiQNS9omqV9St6ScpIKkr5I+SspLmpT0yMx+NMpbUIC1wEVggeXzDjgHdMX2XxfAQWC+iuDl5IGh2DlqAjgKLNURPuUbsCd2nqoAdgLfHcKnLACbYudaFkA78MoxfMq92NmWBXAkQPiUYW+/OW9BSScCaKaMegu6fgcAfZJmFaawkvRF0hozW/QS9DY6GECzlC5JGzwFvc32OutVosdTzLsAHc56lWj3FAvZrisC7wIUnPWC412AX856lXA9Ja7EDnAtsncB3Pbnf9DUHdCIIUZTF6ARHeD6jKwAnmKSPjvrlZPOD93wLsBbZ71yFpUciNzwLsAbhe2COTNr3m2waG7SU7OM+96CIc4CNwNoptzwFnS/GCG5AMnL/2j81My2OGv6d0BxWnPNW1fSRADNMFdjwHpJM/I7u7+XtNHMXLdAKdA8wMxeS7rlKHk5RPigAP3AT4dR+DywKpTPYBMhM3sp6bqD1LiZfXLQaTzAOqq7FS5nBuiMnaMugJM1hl8C9sb2XzdADrhdQwHGG+Ev2BpQDD4g6ZhqG2P1ASMki+nKmF4XQw8Bl4DZGlu/EnlgAtjelMUAuoDjwHPH0H/jBcm60h07t4Be4AL1/QZTK/PAGLA6RvCe4sPr2eK8WCB5CcE+lkqDdwJnifPG/8cH4AzJadQ9eBtwmGQxanZmgUNAm0fwHHCAxixu3jwj8V79rkESfD8wHTWCD9NUUwhgM/AgquUwPAQGy/PmSoLngFOSnkjaXXXbND+7JE0BpynpBpOS8JKuSBqJZK7RXJU0amaFtBLn1TrhpSTrmCQZsFXSlFrvd5mCpB0G3JG0L7abSNzNSRqI7SIi/a3W9n+QFSC2gdhkBYhtIDZtkh5LmovsIxah/2jJyMhocn4DhLkb2GSLSaIAAAAASUVORK5CYII=);
      }
      .name-block {
        margin-bottom: -1px;
      }
      .name {
        margin-top: 0;
        margin-bottom: 0;

        line-height: 1.125rem;
        &.hello {
          text-transform: capitalize;

          + p.name {
            display: inline-block;
            max-width: 6rem;
            text-overflow: ellipsis;
            overflow: hidden;
            color: $actionBtnBg;
          }
        }
      }
    }
    // activate voucher
    &.voucher,
    &.withdraw {
      .btn {
        margin-bottom: 0;
      }
    }

    &:hover {
      transition: all ease-out 300ms;
      filter: drop-shadow(0 0 0.25rem $actionBtnBg);
    }
  }
  &-item + .login-menu-item {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 1367px) {
  .login-menu-item.withdraw.btn {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
}

.hidden{
  display: none;
}
