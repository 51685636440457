.confirm-place-bets {
  transition: bottom ease-out 300ms;

  position: absolute;
  left: 0;

  bottom: -20rem;
  z-index: 9999;

  padding: 2rem 1rem;
  width: 100%;

  background: $modalBackdropBgDark;

  &-text {
    margin: 0 0 1rem;
  }

  &-btns {
    margin-bottom: 0.5rem;
   .action-button {
     display: inline-block;
     width: 20%;
     min-width: 90px;

     text-transform: initial;
     &:first-child {
       margin-right: 10%;
       width: 40%;
       min-width: 140px;
     }
   }
  }
  &-remember {

  }

  &.show {
    transition: bottom ease-out 300ms;

    bottom: 0;
  }
}
