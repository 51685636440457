@import "./../variables";

.video-container {
  display: block;
  position: relative;
  box-sizing: content-box;
  height: 30.5rem;
  min-height: 320px;
  width: 100%;
  overflow: hidden;
  background-color: black;
  margin-bottom: 0.625rem;
  transition: height 360ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  &.active {
    height: 18.125rem;
  }
  &.fullscreen {
    display: block;
    height: 100%;
  }
  &.hidden {
    display: none;
  }


  .countdown-container {
    transform: translate(-50%,-50%);

    position: absolute;
    top: 50%;
    left: 50%;

    width: 10vw;

    font-size: 3vw;
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .video-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10003;
  }
  #mohiogaming {
    margin-bottom: 80px;
  }
}
.player-container {
  opacity: 0;
}
.game-video {
  &-content {
    background-image: url("./../../assets/images/video/video-background.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    height: 100%;
    width: 100%;
    margin: auto;
    position: relative;
    transition: width 360ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    &.horses {
      background-image: url("./../../assets/images/login/horse_racing.jpg");
    }
    &.active {
      width: 67%;
    }
    &.backgroundSizeZero {
      background-size: 0;
      display: flex;
      align-items: center;
    }
  }
  &-logo {
    background-image: url("./../../assets/images/video/logo-dogs-6.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 7.875rem;
    width: 25%;
    position: absolute;
    top: 2%;
    left: 2%;
    z-index: 1;
  }
  &-circular-progressbar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15.5vw;
    opacity: 0.9;
  }
  &-circular-progressbar-label {
    position: absolute;
    top: 30%;
    width: 100%;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
  }

  &-header-block {
    background-color: $videoHeaderColor;
    position: relative;
    z-index: 1;
    padding-top: 0.1rem;
    align-items: center;
    height: 5.85rem;
    width: 100%;
    top: 0;
    right: 0;
    font-size: 1.425rem;
    color: #ffffff;
    font-family: inherit;
    font-weight: 400;
    line-height: 0.01rem;
    text-align: center;
    display: none;

    .top-title {
      font-size: 1.825rem;
    }
  }

  &-live-block {
    background-image: linear-gradient(
                    180deg,
                    rgba(209, 54, 61, 1) 0%,
                    rgba(209, 54, 61, 1) 45%,
                    rgba(201, 24, 27, 1) 50%,
                    rgba(201, 24, 27, 1) 100%
    );
    position: absolute;
    z-index: 1;
    align-content: center;
    height: 5.25rem;
    width: 8.562rem;

    //height: 5.25rem;
    //width: 8.562rem;
    top: 0;
    right: 0;
  }

  &-live {
    background-image: url("./../../assets/images/video/live-icon.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 6rem;

    position: relative;
    z-index: 1;
    height: 50%;
    width: 100%;
  }

  &-title {
    position: relative;
    font-size: 1rem;
    color: #ffffff;
    font-family: inherit;
    font-weight: 700;
    line-height: 1.875rem;
    text-align: center;
  }

  &-fullscreen {
    background-image: url("./../../assets/images/video/fullscreen-icon.png");
    background-position: bottom right;
    background-repeat: no-repeat;
    position: absolute;
    height: 4rem;
    width: 4rem;
    bottom: 0;
    right: 0;
    cursor: pointer;
    z-index: 1;
    &.exit {
      display: none;
    }
    &.fullscreen {
      bottom: 51%;
      display: block;
    }
  }

  &-volume {
    background-image: url("./../../assets/images/video/volume.png");
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 50%;
    position: absolute;
    height: 4rem;
    width: 4rem;
    margin: 1rem;
    bottom: 0;
    left: 0;
    cursor: pointer;
    z-index: $preloaderLayer;
    &.mute {
      background-image: url("./../../assets/images/video/mute.png");
      background-size: 45%;
      margin: 1.1rem;
    }
    &.fullscreen {
      bottom: 51%;
    }
  }

  &-close-button {
    margin-bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 1;

    bottom: 0;
    right: 0;

    display: none;

    &.fullScreen {
      display: block;
    }
  }

  //@media screen and (max-width: 1439px) {
  //  &-title {
  //    font-size: 1rem;
  //  }
  //
  //  &-live {
  //    background-size: 6rem;
  //  }
  //  &-live-block {
  //    height: 5.25rem;
  //    width: 8.562rem;
  //
  //  }
  //}
}

.video-filler {
  display: none;
  background: #68b2e3;
  height: 0.6rem;
  opacity: 1;
  position: absolute;
  border-radius: inherit;
  transition: height 0.2s ease-in;
  z-index: 1;
  bottom: 0;
}

.background-preloader-fadeout {
  animation: 1s ease 4s normal forwards 1 fadeout;
  -webkit-animation: 1s ease 4s normal forwards 1 fadeout;
}
.background-video-fadein {
  animation: 1s ease 3s normal forwards 1 fadein;
  -webkit-animation: 1s ease 3s normal forwards 1 fadein;
}
.shaka-controls-container {
 display: none;
}
@keyframes fadeout {
  0% {
    opacity: 1;
    z-index: 9002;
  }
  90% {
    opacity: 0;
    z-index: 9002;
  }
  100% {
    opacity: 0;
    z-index: 9002;
  }
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
    z-index: 9002;
  }
  90% {
    opacity: 0;
    z-index: 9002;
  }
  100% {
    opacity: 0;
    z-index: 9002;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.show-video-preloader {
  animation: fade-in-custom 700ms linear;
  display: block;
}

.video-preloader-dots {
  margin-top: 35%;
}
.video-preloader-dots:after {
  display: inline-block;
  animation: ellipsis 1.25s infinite;
  content: ".";
  width: 1em;
  text-align: left;
}

@keyframes fade-in-custom {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(3);
  }
}

@keyframes ellipsis {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
}

@media screen and (max-height: 1000px) {
  .game-video-circular-progressbar {
    width: 14vw;
  }
  .game-video-circular-progressbar-label {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: $maxMobileScreenWidth) {
  .game-video-live {
    height: 80%;
  }
  //.video-container,
  .game-video-title,
  .game-video-logo,
  .game-video-fullscreen {
    display: none;
  }
  .game-video-live-block {
    height: 3.45rem;
    width: 100%;
    position: relative;
    background-position: center;
  }
  .game-video-close-button,
  .game-video-header-block {
    display: block;
    z-index: 9999;
  }

  .player-container {
    //top: 40%;
  }
}
