$bottomBorderOfTitle: 0.375rem;
.tabs {
  &-titles {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: $bottomBorderOfTitle;
    background: $tabTitles;
  }

  &-titles-item {
    transition: all ease-out 200ms;

    position: relative;

    flex-grow: 1;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;

    cursor: pointer;
    background: $tabTitles;

    text-align: center;
    font-weight: 700;
    font-size: 1.125rem;
    color: $tabTitleText;
    text-transform: uppercase;

    &.active {
      transition: all ease-out 300ms;

      background-image: linear-gradient(0deg, #0069a5 0%, #0081cb 100%);

      color: $white;

      &::after {
        background: $tabTitlesBorder;
      }
    }
    
    &.disable {
      cursor: not-allowed;
      background-color: $disableStateBg;
    }

    &:hover {
      transition: all ease-out 200ms;

      color: $white;
    }

    &::after {
      content: "";

      transition: background-color ease-out 300ms;

      position: absolute;
      bottom: -$bottomBorderOfTitle;
      left: 0;

      width: 100%;
      height: $bottomBorderOfTitle;

      background: transparent;
    }
  }

  &-content {
    //height: 300px;

    &-item {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}
