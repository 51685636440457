$gameListWidth: 210px;

.right-header-block {
  width: 0 !important;
  display: block;
}

.mobile-header {
  .header-aside,
  .header-center {
    display: inline-block;
    width: 30%;
    vertical-align: middle;
  }

  .header-center {
    width: 40%;
  }

  // list of games(center of header)
  .active-game-logo {
    position: relative;

    &-active.no-active-game-logo {
      background-image: url("./../../assets/images/header/logo_no_game.png");
    }
    &-active {
      position: relative;

      display: inline-block;
      width: 157px;
      height: 82px;

      cursor: pointer;
      background-repeat: no-repeat;
      background-size: contain;

      &::after {
        transition: all ease-out 300ms;
        content: "";

        position: absolute;
        bottom: 10px;
        right: 15px;

        display: block;
        height: 10px;
        width: 16px;

        background-image: url("./../../assets/images/header/arrow-game.png");
      }
    }
    &-active.open {
      &::after {
        transform: rotate(180deg);
      }
    }
    &-active.dog-6 {
      background-image: url("./../../assets/images/header/dog6logo.png");
    }
    &-active.dog-8 {
      background-image: url("./../../assets/images/header/dog8logo.png");
    }
    &-active.horse-6 {
      background-image: url("./../../assets/images/header/horse6logo.png");
    }
    &-active.horse-8 {
      background-image: url("./../../assets/images/header/horse8logo.png");
    }
  }
  .games-list {
    transition: all ease-out 300ms;
    transform: translate(-50%, 0);

    position: absolute;
    top: 100%;
    left: 50%;
    z-index: $gameSelectLayer;

    flex-direction: column;

    background-color: $containersBg;

    box-shadow: 0px 0px 1px rgba(255, 255, 255, 0.9);

    > li {
      &.games-list-item {
        flex: 0 0 auto;

        margin-right: 0;
        margin-top: 0;
        width: $gameListWidth;
        height: 100px;

        background-repeat: no-repeat;
        background-size: auto auto;
        background-color: $containersBg;
        background-position: center center;
        border-top: 1px rgba(255, 255, 255, 0.3) solid;

        &.active {
          background-color: $bgContainerBgLighter;
        }

        & + .games-list-item {
          margin-right: 0;
        }

        .games-list-item-name {
          display: none;
        }

        &:hover {
          transition: all ease-out 400ms;
          box-shadow: 0 0 0.375rem $actionBtnBg;
        }
      }
    }

    .games-list-item.DOG_6,
    .games-list-item.DOG_6_WEB{
      background-image: url("./../../assets/images/header/dog6logo.png");
    }
    .games-list-item.DOG_8,
    .games-list-item.DOG_8_WEB{
      background-image: url("./../../assets/images/header/dog8logo.png");
    }
    .games-list-item.HORSE_6,
    .games-list-item.HORSE_6_WEB{
      background-image: url("./../../assets/images/header/horse6logo.png");
    }
    .games-list-item.HORSE_8,
    .games-list-item.HORSE_8_WEB{
      background-image: url("./../../assets/images/header/horse8logo.png");
    }
  }

  // left login menu
  .login-menu.login-menu-right {
    align-items: baseline;
  }

  .login-menu-item.credits {
    text-align: right;
    font-size: 12px;
    span {
      display: block;
    }
  }
  .login-menu-item.logout span,
  .login-menu-item.basket-btn span,
  .login-menu-item.menu span,
  .login-menu-item.back-btn span {
    width: 30px;
    height: 36px;
    margin-bottom: 0;
    background-repeat: no-repeat;
  }
  //.login-menu-item.menu span {
  //  width: 30px;
  //}

  .login-menu-item.basket-btn span {
    display: block;

    background-image: url("./../../assets/images/header/basket.png");
  }

  .login-menu-item.back-btn span {
    transition: all ease-out 500ms;

    display: block;
    opacity: 0;
    height: 32px;
    width: 0;
  }

  .login-menu-item.back-btn.show span {
    transition: all ease-out 500ms;

    opacity: 1;
    width: 32px;

    background-image: url("./../../assets/images/header/back-btn.png");
  }

  .jackpot-block {
    margin: 0 -8px -7px;
    padding: 9px 0 9px;

    background-color: $itemBg;

    text-transform: uppercase;
    color: $jackpotColor;
    font-size: 0.875rem;
    font-weight: 600;

    .super-jackpot {
      display: inline-block;
      padding-left: 15px;
      margin-left: 15px;

      border-left: 1px rgba(255, 255, 255, 0.4) solid;

      color: $white;
    }
  }

  .address-block {
    margin: 0 -8px -7px;
    padding: 9px 0 9px;

    background-color: $itemBg;

    color: $jackpotColor;
    font-size: 0.875rem;
    font-weight: 600;

    .label-address {
      text-transform: uppercase;
    }
    .address {
      display: inline-block;
      color: $white;
    }
  }
  @media screen and (max-width: 520px) {
    .header-aside {
      width: 30%;
    }

    .header-center {
      width: 35%;
    }

    .header-aside.right-menu {
      width: 35%;
    }

    .active-game-logo-active {
      width: 120px;
      height: 58px;
      &::after {
        bottom: 0;
        right: 10px;
      }
    }

    .login-menu .login-menu-item.credits {
      margin-right: 0.25rem;
    }

    .address-block {
      .label-address {
        font-size: 10px;
      }
      .address {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: -3px;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 359px),
    screen and (orientation: landscape) and (max-height: 568px) {
    .active-game-logo-active {
      width: 100%;
      height: 50px;
    }
  }
}



@media screen and (max-width: 359px) {
  .login-menu-item.username .name.hello + p.name {
    max-width: 4rem;
  }
}


@media screen and (max-width: $maxMobileScreenWidth) {
  .super-jackpot-mobile-text-shadow {
    -webkit-animation: blink 2s linear infinite;
    -moz-animation: blink 2s linear infinite;
    -ms-animation: blink 2s linear infinite;
    -o-animation: blink 2s linear infinite;
    animation: blink 2s linear infinite;
  }

  .jackpot-mobile-text-shadow {
    -webkit-animation: golden-blink 2s linear infinite;
    -moz-animation: golden-blink 2s linear infinite;
    -ms-animation: golden-blink 2s linear infinite;
    -o-animation: golden-blink 2s linear infinite;
    animation: golden-blink 2s linear infinite;
  }
  .mobile-header .login-menu.login-menu-right {
    align-items: flex-end;
  }
  .login-menu-item.username span {
    display: none;
  }
}
