@import "./../variables";

@import "./Betlist";
@import "./Betslip";

.hide {
  display: none;
}
.basket {
  display: block;
  position: relative;
  box-sizing: content-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: 100%;
  background-color: $bgContainerBgLighter;

  .tabs .tabs-titles .tabs-titles-item:not(.active) {
    background: $containersBg;
  }
  .basket-tab-content {
    padding: 1.25rem;
  }

  .show-in-parent.pre-loader-container {
    background-color: rgba(38, 41, 43, 0.9);
    opacity: 1;
  }
  .pre-loader-container.transparentAbsolutePreLoader {
    z-index: 5;
  }

  .btn.accordion-toggle.accordion-toggle-alarm {
    background-image: none;
    background-color: #d1413d;
  }
}

@media screen and (max-width: $maxMobileScreenWidth) {
  .body {
    position: relative;
  }
  .basket-aside {
    transition: all ease 300ms;

    position: fixed;
    height: 100%;
    width: 100%;
    right: -100%;

    &.show-aside {
      transition: all ease 300ms;

      position: absolute;
      right: 0;
      z-index: $asideBlockLayer;
    }

    &.preloader-layer {
      z-index: $preloaderLayer;
    }

    &.bet-history-mobile,
    &.bet-slip-mobile {
      .tabs .tabs-titles .tabs-titles-item.active {
        z-index: 9;
      }
      .tabs .tabs-titles .tabs-titles-item:not(.active) {
        display: none;
      }
    }

    .basket .tabs .tabs-titles .tabs-titles-item {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      &::after {
        display: none;
      }
    }

    .basket .tabs .tabs-content .basket-tab-content {
      padding: 0 0.5rem 0.5rem;
    }

    .basket .tabs .tabs-content footer.stake-operation-block {
      padding: 0.75rem 0.5rem 0rem;

      .increments-list li {
        padding: 0.75rem 0.375rem;
      }

      .action-button.place-bet-button {
        margin: 3px -4px 0;
      }
    }
  }
}
